import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"

function Header({siteTitle}) {

  let [mobileNavOpen, setMobileNavOpen] = useState(false);

  return (<header className={mobileNavOpen ? "menu-open" : ""}>
        <div className="row navbar" id="navbar">
        
        <div className="three columns navbar__logo">
        <Link to="/"> 
        <h3>{siteTitle}</h3>
        <h5>Web Developer</h5>
        </Link>
        <div className={mobileNavOpen ? "burger-icon nav-open" : "burger-icon" } id="toggleMenu" onClick={ () => setMobileNavOpen(!mobileNavOpen) }>
        <span className="burger-icon__line"></span>
        <span className="burger-icon__line"></span>
        <span className="burger-icon__line"></span>
        </div>
        </div>

        <div className={mobileNavOpen ? "seven columns main-menu show" : "seven columns main-menu"}>
        
        <ul className="menu">
          <Link to="/" className="menu__item" activeClassName="menu__item--active"><li>Home</li></Link>
          <Link to="/about" className="menu__item" activeClassName="menu__item--active"><li>About</li></Link>
          <Link to="/portfolio" className="menu__item" activeClassName="menu__item--active" partiallyActive={true}><li>Portfolio</li></Link>
          <Link to="/contact" className="menu__item" activeClassName="menu__item--active"><li>Contact Me</li></Link>
        </ul> 

        </div>
        
        </div>

    </header>);
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
